import { useContext, useEffect, useState } from "react";
import Navbar from "../Navbar";
import { AuthContext } from "../../provider/AuthProvider";
import {
  collection,
  getDocs,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/FirebaseConfig";
import { useTranslation } from "react-i18next";
import {
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/16/solid";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import Info from "../Info";
import Package from "../Package";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";

const Documents = () => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [table, setTable] = useState([]);

  if (!user) {
    navigate("/");
  }

  const fetchData = async () => {
    if (user !== null) {
      // Get the current time
      const now = Timestamp.now();
      // Calculate the timestamp for 24 hours ago
      const twentyFourHoursAgo = new Timestamp(
        now.seconds - 24 * 60 * 60,
        now.nanoseconds
      );

      const docRef = query(
        collection(db, "stately_task"),
        where("user_id", "==", user.uid),
        where("created_date", ">", twentyFourHoursAgo)
      );

      const docSnap = await getDocs(docRef);
      const userDoc = [];

      docSnap.forEach((e) => {
        userDoc.push({
          name: e.data().url_document,
          xls: e.data().exported_file?.xls ?? null,
          pdf: e.data().url_document,
        });
      });

      setTable(userDoc);
    } else {
      setTable([]);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleDownloadXLS = (url) => {
    try {
      window?.gtag("event", "download_converted_file"); // add event GA
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadPDF = (url) => {
    try {
      window?.gtag("event", "download_converted_file"); // add event GA
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Navbar />
      <div className="grid grid-cols-1 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 justify-items-center">
        <div className="w-full grid grid-cols-1 content-start items-start rounded-md gap-y-6">
          <div className="grid grid-cols-1 gap-y-1">
            <p className="text-2xl font-bold text-start">
              {t("document_title")}
            </p>
            <p className="text-base font-normal text-start text-gray-600">
              {t("document_subtitle")}
            </p>
          </div>
          <div className="h-[400px] grid grid-flow-row items-start justify-normal content-start gap-y-6 bg-gray-200 rounded-xl p-4 overflow-auto">
            {table.length === 0 && (
              <p className="text-base text-left font-semibold">
                No Document Yet
              </p>
            )}

            {table?.map((item, index) => (
              <div
                key={index}
                className="grid grid-flow-col h-fit items-center justify-between gap-x-4"
              >
                <div className="flex items-center gap-x-2 w-full p-[0.5rem] text-left text-base font-semibold text-ellipsis">
                  <DocumentTextIcon className="w-5 h-5" />
                  <p>{item.name.substring(63, item.name.length)}</p>
                </div>

                <div className="grid grid-flow-col gap-x-3">
                  <button
                    onClick={() => handleDownloadPDF(item.pdf)}
                    className="flex items-center gap-x-2 max-w-[300px] min-w-[100px] py-[0.5rem] px-3 text-white text-center text-[0.875rem] font-normal bg-primary rounded-lg"
                  >
                    <MagnifyingGlassIcon className="w-4 h-4" />
                    {t("document_inspect")}
                  </button>
                  <button
                    onClick={() => handleDownloadXLS(item.xls)}
                    className="flex items-center gap-x-2 max-w-[400px] min-w-[100px] py-[0.5rem] px-3 text-white text-center text-[0.875rem] font-normal bg-primary rounded-lg"
                  >
                    <ArrowDownTrayIcon className="w-4 h-4" />
                    {t("document_download")}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Info />
      <Package />
      <Footer />
    </>
  );
};

export default Documents;
