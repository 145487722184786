import React from "react";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();
  return (
    <header className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24">
      <h1 className="font-bold text-2xl sm:text-3xl">{t("home_title")}</h1>
      <p
        className="mt-2"
        dangerouslySetInnerHTML={{
          __html: t("home_subtitle"),
        }}
      ></p>
    </header>
  );
};

export default Header;
