import React from "react";
import { useTranslation } from "react-i18next";

const Download = ({ output, filename, onReset }) => {
  const { t } = useTranslation();

  const handleDownload = () => {
    try {
      window?.gtag("event", "download_converted_file"); // add event GA
      const newWindow = window.open(output, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="bg-[#EBF0F8] rounded-lg mt-4 p-8 sm:p-8 text-center">
      <p
        className="font-bold max-w-xl mx-auto"
        dangerouslySetInnerHTML={{
          __html: t("home_upload_success"),
        }}
      />
      <div
        className={`flex justify-center rounded-md p-4 mb-8`}
        onClick={handleDownload}
      >
        <p className="flex items-center gap-4 bg-primary text-white text-lg font-bold text-center py-4 px-8 rounded-lg cursor-pointer">
          <img src="/asset/icon/Download.svg" alt="download" width={20} />
          {t("home_upload_download")}
        </p>
      </div>
      <span
        className="text-[#32D07B] text-center w-full font-medium cursor-pointer mt-8 underline"
        onClick={onReset}
      >
        {t("home_upload_reupload")}
      </span>
    </div>
  );
};

export default Download;
