import React from "react";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-8">
      <h1 className="font-bold text-3xl ">{t("home_about")}</h1>
      <p
        className="mt-2"
        dangerouslySetInnerHTML={{
          __html: t("home_about_title"),
        }}
      ></p>
    </section>
  );
};

export default AboutUs;
