import { CheckBadgeIcon } from "@heroicons/react/16/solid";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Package = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const contents = [
    {
      title: t("home_info_package_1_title"),
      desc: t("home_info_package_1_desc"),
      limit: t("home_info_package_1_limit"),
      bill: t("home_info_package_1_bill"),
    },
    {
      title: t("home_info_package_2_title"),
      desc: t("home_info_package_2_desc"),
      limit: t("home_info_package_2_limit"),
      bill: t("home_info_package_2_bill"),
    },
    {
      title: t("home_info_package_3_title"),
      desc: t("home_info_package_3_desc"),
      limit: t("home_info_package_3_limit"),
      bill: t("home_info_package_3_bill"),
    },
  ];

  return (
    <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-16">
      <div className="grid sm:grid-flow-col grid-rows-1 gap-6">
        {contents.map((item, index) => {
          return (
            <div
              key={index}
              className={
                !item.bill.includes("No")
                  ? "grid grid-cols-1 gap-y-6 border border-primary p-6 rounded-lg content-between"
                  : "grid grid-cols-1 gap-y-6 border border-gray-400 p-6 rounded-lg content-between"
              }
            >
              <div className="grid grid-cols-1 gap-y-1">
                <p className="text-left text-lg font-bold">{item.title}</p>
                <p className="text-left text-base font-normal">{item.desc}</p>
              </div>
              {item.limit && (
                <p className="flex gap-x-2 text-left text-base font-normal items-center">
                  <CheckBadgeIcon className="w-5 h-5 fill-green-500" />
                  {item.limit}
                </p>
              )}
              <p
                onClick={() => {
                  if (!item.bill.includes("No")) navigate("/pricing");
                }}
                className={
                  item.bill.includes("No")
                    ? "text-left text-2xl font-bold"
                    : "text-left text-lg font-bold text-primary cursor-pointer"
                }
              >
                {item.bill}
              </p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Package;
