import { useContext, useState } from "react";
import { AuthContext } from "../../provider/AuthProvider";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import { doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import { db } from "../../firebase/FirebaseConfig";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const Register = () => {
  const { user, createUser, loginGoogleUser } = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [focus, setFocus] = useState(false);
  const [error, setError] = useState(null);
  const [validate, setValidate] = useState({
    hasLow: false,
    hasCap: false,
    hasNumber: false,
    has12digit: false,
    hasSymbol: false,
  });

  if (user) {
    navigate("/");
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const email = e.target.email.value;
    const password = e.target.password.value;

    validatePassword(password);

    if (
      validate.has12digit &&
      validate.hasCap &&
      validate.hasLow &&
      validate.hasNumber &&
      validate.hasSymbol &&
      name !== "" &&
      email !== ""
    ) {
      createUser(email, password)
        .then(async (result) => {
          const user = result.user;

          const docRef = doc(db, "stately_users", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            navigate("/");
          } else {
            try {
              await setDoc(doc(db, "stately_users", user.uid), {
                name: name,
                email: email,
                quota_left: 5,
                registered_date: Timestamp.fromDate(new Date()),
                subscriptions: [],
              });
              navigate("/");
            } catch (error) {
              console.log(error);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          setError("Failed to register with provided credentials");
        });
    } else {
      setError(
        "You need to pass all password strength check & fill all the form"
      );
    }
  };

  const handleGoogleSubmit = (e) => {
    e.preventDefault();

    loginGoogleUser()
      .then(async (result) => {
        const user = result.user;

        const docRef = doc(db, "stately_users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          navigate("/");
        } else {
          try {
            await setDoc(doc(db, "stately_users", user.uid), {
              name: user.displayName,
              email: user.email,
              quota_left: 5,
              registered_date: Timestamp.fromDate(new Date()),
              subscriptions: [],
            });
            navigate("/");
          } catch (error) {
            console.log(error);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFocus = () => {
    setFocus(true);
  };
  const handleBlur = () => {
    setFocus(false);
  };

  const validatePassword = (password) => {
    if (password.match(/\d+/g)) {
      setValidate((o) => ({ ...o, hasNumber: true }));
    } else {
      setValidate((o) => ({ ...o, hasNumber: false }));
    }

    if (password.match(/[A-Z]+/g)) {
      setValidate((o) => ({ ...o, hasCap: true }));
    } else {
      setValidate((o) => ({ ...o, hasCap: false }));
    }

    if (password.match(/[a-z]+/g)) {
      setValidate((o) => ({ ...o, hasLow: true }));
    } else {
      setValidate((o) => ({ ...o, hasLow: false }));
    }

    if (password.length > 11) {
      setValidate((o) => ({ ...o, has12digit: true }));
    } else {
      setValidate((o) => ({ ...o, has12digit: false }));
    }

    if (password.match(/[%!@#$%^&*.]+/g)) {
      setValidate((o) => ({ ...o, hasSymbol: true }));
    } else {
      setValidate((o) => ({ ...o, hasSymbol: false }));
    }
  };

  const handleChangePassword = (e) => {
    validatePassword(e.currentTarget.value);
  };

  return (
    <>
      <Navbar />
      <section className="grid grid-cols-1 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 justify-items-center">
        <div className="w-full max-w-xl grid grid-cols-1 content-start items-start border border-gray-300 rounded-md p-[2rem] gap-y-6">
          <p className="text-[32px] font-bold text-start">
            {" "}
            {t("register_title")}
          </p>
          <button
            onClick={handleGoogleSubmit}
            className="border-2 bg-white disabled:bg-opacity-20 rounded-md p-3 font-semibold text-base grid grid-flow-col justify-center items-center gap-x-4"
          >
            <img src="/asset/icon/google.png" alt="logo" className="w-6 h-6" />
            {t("register_with_google")}
          </button>
          <p className="text-center font-semibold">or</p>
          <form
            onSubmit={handleFormSubmit}
            className="grid grid-cols-1 gap-y-6 relative"
          >
            <input
              type="text"
              name="name"
              placeholder={t("register_full_name")}
              className="border border-gray-400 p-3 rounded-md font-semibold text-base"
            />
            <input
              type="text"
              name="email"
              placeholder={t("register_email")}
              className="border border-gray-400 p-3 rounded-md font-semibold text-base"
            />
            <input
              type="password"
              name="password"
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder={t("register_password")}
              className="border border-gray-400 p-3 rounded-md font-semibold text-base"
              onChange={handleChangePassword}
            />
            <button className="bg-primary disabled:bg-opacity-20 rounded-md p-3 font-semibold text-white text-lg">
              {t("register_button")}
            </button>

            {error && (
              <div className="p-2 border-2 border-red-500 rounded-lg">
                <p className="text-center text-red-500">{error}</p>
              </div>
            )}

            {focus && (
              <div
                className={clsx(
                  "grid grid-flow-row",
                  "w-[200px] absolute bg-white",
                  "left-[0px] bottom-[130px] xl:left-[-220px] xl:bottom-[40px]",
                  "py-[6px] px-[12px] gap-y-[6px] rounded-md",
                  "drop-shadow-lg"
                )}
              >
                <p className={clsx("text-left text-base font-bold")}>
                  {t("register_password_strength")}
                </p>
                <div className={clsx("grid grid-flow-row", "gap-y-[0.25rem]")}>
                  <p
                    className={clsx(
                      "text-left text-[0.625rem] font-normal",
                      validate.has12digit ? "text-green-500" : "text-red-500"
                    )}
                  >
                    {t("register_password_12_character")}
                  </p>
                  <p
                    className={clsx(
                      "text-left text-[0.625rem] font-normal",
                      validate.hasCap && validate.hasLow
                        ? "text-green-500"
                        : "text-red-500"
                    )}
                  >
                    {t("register_password_upper_lower")}
                  </p>
                  <p
                    className={clsx(
                      "text-left text-[0.625rem] font-normal",
                      validate.hasNumber ? "text-green-500" : "text-red-500"
                    )}
                  >
                    {t("register_password_mix")}
                  </p>
                  <p
                    className={clsx(
                      "text-left text-[0.625rem] font-normal",
                      validate.hasSymbol ? "text-green-500" : "text-red-500"
                    )}
                  >
                    {t("register_password_symbol")}
                  </p>
                </div>
              </div>
            )}
          </form>
        </div>
      </section>
    </>
  );
};

export default Register;
