import React from "react";
import { useTranslation } from "react-i18next";

const Info = () => {
  const { t } = useTranslation();

  const contents = [
    {
      icon: "/asset/icon/safety_icon.png",
      title: t("home_info_1_title"),
      desc: t("home_info_1_subtitle"),
    },
    {
      icon: "/asset/icon/trusted_icon.png",
      title: t("home_info_2_title"),
      desc: t("home_info_2_subtitle"),
    },
    {
      icon: "/asset/icon/precision_icon.png",
      title: t("home_info_3_title"),
      desc: t("home_info_3_subtitle"),
    },
  ];

  return (
    <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-16">
      <div className="grid sm:grid-flow-col grid-rows-1 gap-x-6 gap-y-10">
        {contents.map((item, index) => {
          return (
            <div
              key={index}
              className="grid grid-cols-1 gap-y-2 items-center content-start justify-items-center"
            >
              <img src={item.icon} alt="" className="w-20 h-20 rounded-full" />
              <p className="text-center text-lg font-bold">{item.title}</p>
              <p className="text-center text-base font-normal">{item.desc}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Info;
