import React from "react";

const Footer = () => {
  return (
    <footer className="max-w-7xl mx-auto px-4 py-4 border-t">
      <p className="text-base">{"Copyright © 2024 Fintelite"}</p>
    </footer>
  );
};

export default Footer;
