import { useContext, useEffect, useState } from "react";
import Navbar from "../Navbar";
import { AuthContext } from "../../provider/AuthProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/FirebaseConfig";

const Pricing = () => {
  const { user, isAnonymous } = useContext(AuthContext);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [billingCycle, setBillingCycle] = useState("MONTH");
  const [items, setItems] = useState([]);

  const redirectToCheckout = async (item) => {
    if (isAnonymous) {
      navigate("/login");
    } else {
      try {
        window?.gtag("event", "purchase_subscription"); // add event GA
        const formData = new FormData();

        formData.append("product_id", item.id);

        await axios
          .post(`${process.env.REACT_APP_BASE_URL}payment/checkout`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${user.accessToken}`,
            },
          })
          .then((response) => {
            // Handle successful file upload response
            const newWindow = window.open(
              response.data.redirect_url,
              "_blank",
              "noopener,noreferrer"
            );
            if (newWindow) newWindow.opener = null;
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const fetchPricingData = async () => {
    try {
      const docRef = doc(db, "stately_config", "general");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();

        let pricing = [];

        data.products.forEach((e) => {
          pricing.push({
            id: e.id,
            productId: e.prod_id,
            title: e.name,
            description: e.description,
            monthlyLimit: e.quota,
            priceId: e.price_id,
            billingCycle: e.billing_cycle,
            price: e.price,
          });
        });

        setItems(pricing || []);
      } else {
        setItems([]);
      }
    } catch (error) {
      setItems([]);
    }
  };

  useEffect(() => {
    fetchPricingData();
  }, []);

  return (
    <>
      <Navbar />
      <div className="min-h-screen max-h-full grid grid-cols-1 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 justify-items-center content-start gap-y-5">
        <div className="grid grid-flow-col h-fit">
          <button
            onClick={() => {
              setBillingCycle("MONTH");
            }}
            className={
              billingCycle === "MONTH"
                ? "px-5 py-3 border-2 border-primary rounded-l-lg text-base font-semibold text-primary"
                : "px-5 py-3 border-2 border-gray-300 rounded-l-lg text-base font-semibold"
            }
          >
            {t("pricing_monthly_plan")}
          </button>
          <button
            onClick={() => {
              setBillingCycle("YEAR");
            }}
            className={
              billingCycle === "YEAR"
                ? "px-5 py-3 border-2 border-primary rounded-r-lg text-base font-semibold text-primary"
                : "px-5 py-3 border-2 border-gray-300 rounded-r-lg text-base font-semibold"
            }
          >
            {t("pricing_yearly_plan")}
          </button>
        </div>
        <div className="h-fit w-full grid grid-cols-1 md:grid-cols-3 xl:grid-flow-col gap-6">
          {items
            .filter((e) => e.billingCycle === billingCycle)
            .map((e, index) => {
              return (
                <div
                  className="h-full grid grid-cols-1 content-center items-center p-5 border rounded-md gap-y-4"
                  key={index}
                >
                  <div>
                    <p className="font-bold text-lg text-center">{e.title}</p>
                    <p className="font-normal text-base text-center">
                      {e.description}
                    </p>
                  </div>
                  <button
                    className="bg-primary py-2 px-4 rounded-md text-white"
                    onClick={() =>
                      redirectToCheckout({
                        price: e.priceId,
                        quantity: 1,
                        monthlyLimit: e.monthlyLimit,
                        id: e.id,
                      })
                    }
                  >
                    Buy
                  </button>
                  <p className="font-normal text-base text-center">
                    {e.monthlyLimit} pages / {e.billingCycle.toLowerCase()}
                  </p>
                </div>
              );
            })}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Pricing;
