import { useContext, useEffect, useState } from "react";
import Navbar from "../Navbar";
import { AuthContext } from "../../provider/AuthProvider";
import {
  collection,
  getDocs,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/FirebaseConfig";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";

const Credits = () => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [table, setTable] = useState([]);
  const [loading, setLoading] = useState(true);

  const head = ["Date", "Description"];

  if (!user) {
    navigate("/");
  }

  const fetchData = async () => {
    try {
      setLoading(true);
      if (user !== null) {
        // Get the current time
        const now = Timestamp.now();
        // Calculate the timestamp for 24 hours ago
        const twentyFourHoursAgo = new Timestamp(
          now.seconds - 24 * 60 * 60 * 30,
          now.nanoseconds
        );

        const docRef = query(
          collection(db, "stately_task"),
          where("user_id", "==", user.uid),
          where("created_date", ">", twentyFourHoursAgo)
        );

        const docSnap = await getDocs(docRef);
        const userDoc = [];

        docSnap.forEach((e) => {
          userDoc.push({
            name: e.data().created_date.toDate().toLocaleString(),
            pdf: `Converted a ${e
              .data()
              .url_document.substring(63, e.data().url_document.length)}`,
          });
        });

        setTable(userDoc);
      } else {
        setTable([]);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <Navbar />
      <div className="h-screen grid grid-cols-1 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 justify-items-center">
        <div className="w-full grid grid-cols-1 content-start items-start rounded-md gap-y-6">
          <p className="text-[32px] font-bold text-start">
            {t("credit_title")}
          </p>

          {!loading && (
            <table className="w-full">
              <thead>
                <tr>
                  {head?.map((item, index) => (
                    <th
                      key={index}
                      className="bg-primary p-[0.75rem] text-left text-[0.875rem] text-white font-normal border-[1px] border-primary"
                    >
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {table?.map((item, index) => (
                  <tr key={index}>
                    {Object.keys(item).map((key, keyIndex) => (
                      <td
                        key={keyIndex}
                        className="bg-alice-blue-dark text-left bg-white border-[1px] border-black"
                      >
                        <p className="p-[0.75rem] text-left text-base font-normal">
                          {item[key]}
                        </p>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Credits;
