import React, { useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import Loader from "./Loader";
import Download from "./Download";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/FirebaseConfig";
import { AuthContext } from "../provider/AuthProvider";
import { useTranslation } from "react-i18next";

const PDFtoExcelConverter = () => {
  const { t } = useTranslation();

  const { user, deviceId } = useContext(AuthContext);
  const [files, setFiles] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [estimatedTime, setEstimatedTime] = useState(null);
  const [output, setOutput] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadStatus, setUploadStatus] = useState(null);
  const [uploadID, setUploadID] = useState(null);

  const onDrop = async (acceptedFiles) => {
    const allowedTypes = ["application/pdf"];
    const maxSizeInBytes = 5 * 1024 * 1024; // 10 MB

    if (acceptedFiles[0].size > maxSizeInBytes) {
      setErrorMessage("Max file size is 5 MB per upload");
      setFiles(null);
      return;
    }

    if (!allowedTypes.includes(acceptedFiles[0].type)) {
      setErrorMessage("Invalid file type. Please select a PDF file.");
      setFiles(null);
      return;
    }

    setIsSubmitting(true);
    setErrorMessage("");
    setFiles(acceptedFiles[0]);

    // submit to server
    try {
      window?.gtag("event", "upload_file"); // add event GA
      const formData = new FormData();
      formData.append("file", acceptedFiles[0]);

      const startTime = Date.now();

      let headers = {
        "Content-Type": "multipart/form-data",
      };

      if (user) {
        Object.assign(headers, { Authorization: `Bearer ${user.accessToken}` });
      }

      if (!user) {
        Object.assign(headers, { DeviceId: `${deviceId}` });
      }

      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}job/runasync/extract`,
          formData,
          {
            headers: headers,
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(progress);

              const currentTime = Date.now();
              const elapsedTime = currentTime - startTime;
              const remainingTime = ((100 - progress) / progress) * elapsedTime;
              setEstimatedTime(remainingTime);
            },
          }
        )
        .then((response) => {
          // Handle successful file upload response
          setIsSubmitting(true); // Show the loading spinner

          const endTime = Date.now(); // End time for measuring response time
          const responseTimeInSeconds = (endTime - startTime) / 1000;
          setEstimatedTime(responseTimeInSeconds);

          setTimeout(() => {
            setUploadID(response.data.id);
            setIsSubmitting(false); // Hide the loading spinner after a simulated delay
            // Add any further actions you want to take after successful upload and response
          }, 2000); // Simulated delay of 2 seconds
        })
        .catch((error) => {
          setIsSubmitting(false);
          setUploadID(null);
          setErrorMessage(
            error?.response?.data?.message ?? "Something error on server"
          );
        });
    } catch (error) {
      setIsSubmitting(false);
      setUploadID(null);
      setErrorMessage(
        error?.response?.data?.message ?? "Something error on server"
      );
    }

    // Set a timeout to estimate response time
    setTimeout(() => {
      if (!estimatedTime) {
        setEstimatedTime(null); // Reset responseTime when the response is still not ready
      }
    }, 5000); // Wait for 5 seconds before assuming the response is not ready
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".pdf",
    multiple: false,
    onFileDialogOpen: () => {
      window?.gtag("event", "click_upload_button");
    },
  });

  useEffect(() => {
    if (uploadID) {
      const docRef = doc(db, "stately_task", uploadID);
      const unsubscribe = onSnapshot(
        docRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();

            setUploadStatus(data["status"]);
            if (data.exported_file?.xls) {
              setOutput(data.exported_file.xls ?? null);
            }
          }
        },
        (error) => console.error("Error with real-time listener:", error)
      );

      // Cleanup listener on component unmount
      return () => unsubscribe();
    }
  }, [user, uploadID]);

  return (
    <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
      {!files && !isSubmitting && (
        <div className="bg-[#EBF0F8] rounded-lg mt-4 p-8 sm:p-20 text-center">
          <div
            {...getRootProps()}
            className={`flex justify-center rounded-md p-4 `}
          >
            <input
              {...getInputProps()}
              type="file"
              accept="application/pdf"
              multiple={false}
            />
            <p
              className={
                "bg-primary hover:bg-blue-500 text-white text-lg font-bold text-center py-4 px-8 rounded-lg cursor-pointer"
              }
            >
              {isDragActive ? t("home_upload_drop") : t("home_upload")}
            </p>
          </div>
          <span className="text-[#697584] text-center w-full">
            <p style={{ color: "#697584" }}>{t("home_upload_file_size")}</p>
            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          </span>
        </div>
      )}

      {files && !isSubmitting && errorMessage && (
        <div className="bg-[#EBF0F8] rounded-lg mt-4 p-8 sm:p-20 text-center">
          <div
            {...getRootProps()}
            className={`flex justify-center rounded-md p-4 `}
          >
            <input
              {...getInputProps()}
              type="file"
              accept=".pdf"
              multiple={false}
            />
            <p
              className={
                "bg-primary hover:bg-blue-500 text-white text-lg font-bold text-center py-4 px-8 rounded-lg cursor-pointer"
              }
            >
              {isDragActive ? t("home_upload_drop") : t("home_upload")}
            </p>
          </div>
          <span className="text-[#697584] text-center w-full">
            <p style={{ color: "#697584" }}>{t("home_upload_file_size")}</p>
            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          </span>
        </div>
      )}

      <Loader
        isSubmitting={isSubmitting}
        progress={progress}
        estimatedTime={estimatedTime}
        fileName={files?.name}
      />

      {!errorMessage &&
        files &&
        !isSubmitting &&
        uploadStatus !== "COMPLETED" && (
          <div className="bg-[#EBF0F8] rounded-lg mt-4 p-8 sm:p-8 text-center">
            <p
              className="font-bold max-w-xl mx-auto"
              dangerouslySetInnerHTML={{
                __html: t("home_upload_progress"),
              }}
            />
            <div className={`flex justify-center rounded-md p-4 mb-8`}>
              <p className="flex items-center gap-4 bg-primary bg-opacity-20 text-white text-lg font-bold text-center py-4 px-8 rounded-lg cursor-pointer">
                <img src="/asset/icon/Download.svg" alt="download" width={20} />
                {t("home_upload_download")}
              </p>
            </div>
          </div>
        )}

      {!errorMessage &&
        files &&
        !isSubmitting &&
        uploadStatus === "COMPLETED" && (
          <Download
            output={output}
            onReset={() => {
              setFiles(null);
              setUploadID(null);
              setIsSubmitting(false);
              setErrorMessage(null);
            }}
            filename={files?.name}
          />
        )}
    </section>
  );
};

export default PDFtoExcelConverter;
