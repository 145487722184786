import { useContext, useState } from "react";
import { AuthContext } from "../../provider/AuthProvider";
import { NavLink, useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import { useTranslation } from "react-i18next";
import { doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import { db } from "../../firebase/FirebaseConfig";

const Login = () => {
  const { user, loginUser, loginGoogleUser } = useContext(AuthContext);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [error, setError] = useState(null);

  if (user) {
    navigate("/");
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    loginUser(email, password)
      .then(async (result) => {
        const user = result.user;

        const docRef = doc(db, "stately_users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          navigate("/");
        } else {
          try {
            await setDoc(doc(db, "stately_users", user.uid), {
              name: user.displayName,
              email: user.email,
              quota_left: 5,
              registered_date: Timestamp.fromDate(new Date()),
              subscriptions: [],
            });
            navigate("/");
          } catch (error) {
            console.error(error);
          }
        }
      })
      .catch((error) => {
        setError("Failed to login with provided credentials");
      });
  };

  const handleGoogleSubmit = (e) => {
    e.preventDefault();

    loginGoogleUser()
      .then(async (result) => {
        const user = result.user;

        const docRef = doc(db, "stately_users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          navigate("/");
        } else {
          try {
            await setDoc(doc(db, "stately_users", user.uid), {
              name: user.displayName,
              email: user.email,
              quota_left: 5,
              registered_date: Timestamp.fromDate(new Date()),
              subscriptions: [],
            });
            navigate("/");
          } catch (error) {
            console.error(error);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Navbar />
      <section className="grid grid-cols-1 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 justify-items-center">
        <div className="w-full max-w-xl grid grid-cols-1 content-start items-start border border-gray-300 rounded-md p-[2rem] gap-y-6">
          <p className="text-[32px] font-bold text-start">{t("login_title")}</p>
          <form
            onSubmit={handleFormSubmit}
            className="grid grid-cols-1 gap-y-6"
          >
            <input
              type="text"
              name="email"
              placeholder={t("login_email")}
              className="border border-gray-400 p-3 rounded-md font-semibold text-base"
            />
            <input
              type="password"
              name="password"
              placeholder={t("login_password")}
              className="border border-gray-400 p-3 rounded-md font-semibold text-base"
            />
            <button className="bg-primary disabled:bg-opacity-20 rounded-md p-3 font-semibold text-white text-base">
              {t("login_button")}
            </button>
            {error && (
              <div className="p-2 border-2 border-red-500 rounded-lg">
                <p className="text-center text-red-500">{error}</p>
              </div>
            )}
          </form>
          <p className="text-center font-semibold">or</p>
          <button
            onClick={handleGoogleSubmit}
            className="border-2 bg-white disabled:bg-opacity-20 rounded-md p-3 font-semibold text-base grid grid-flow-col justify-center items-center gap-x-4"
          >
            <img src="/asset/icon/google.png" alt="logo" className="w-6 h-6" />
            {t("login_with_google")}
          </button>
          <NavLink
            to={"/forgot-password"}
            className="text-center font-semibold"
          >
            Forgot Password
          </NavLink>
        </div>
      </section>
    </>
  );
};

export default Login;
