import { createBrowserRouter } from "react-router-dom";
import Login from "../components/login/Login";
import Upload from "../components/upload/Upload";
import Pricing from "../components/pricing/Pricing";
import Register from "../components/register/Register";
import Credits from "../components/credits/Credits";
import Documents from "../components/documents/Documents";
import ForgotPassword from "../components/forgot_password/ForgotPassword";
import Settings from "../components/settings/Settings";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Upload />,
  },
  {
    path: "/pricing",
    element: <Pricing />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/credit-usage",
    element: <Credits />,
  },
  {
    path: "/documents",
    element: <Documents />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  { path: "/settings", element: <Settings /> },
  {
    path: "*",
    element: <div />,
  },
]);

export default router;
