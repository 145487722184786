import React, { useEffect } from "react";
import { useContext } from "react";
import { AuthContext } from "../provider/AuthProvider";
import { NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/FirebaseConfig";
import { useTranslation } from "react-i18next";
import HamburgerMenu from "./HamburgerMenu";

const Navbar = () => {
  const { user, logOut, isAnonymous } = useContext(AuthContext);
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const [quota, setQuota] = useState(null);

  const handleSingOut = () => {
    logOut().catch((error) => console.error(error));
  };

  const handleNavigateToRoot = () => {
    navigate("/");
  };

  const handleChangeLanguage = (e) => {
    const languageValue = e.target.value;
    i18n.changeLanguage(languageValue);
  };

  const fetchQuota = async () => {
    if (user !== null && !isAnonymous) {
      const docRef = doc(db, "stately_users", user.uid);
      const unsubscribe = onSnapshot(
        docRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            let quota = 0;
            const data = docSnapshot.data();

            const quotaSubscriptions =
              data.subscriptions
                ?.map((e) => e.quota_left)
                .reduce((a, b) => a + b, 0) ?? 0;

            quota = quota + data.quota_left;
            quota = quota + quotaSubscriptions;

            setQuota(quota);
          }
        },
        (error) => console.error("Error with real-time listener:", error)
      );
      return () => {
        unsubscribe();
      };
    } else {
      setQuota(null);
    }
  };

  useEffect(() => {
    fetchQuota();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const navLinks = (
    <>
      <li className="font-medium text-white hover:underline cursor-pointer">
        <NavLink to="/pricing">{t("navbar_pricing")}</NavLink>
      </li>
      {(!user || isAnonymous) && (
        <>
          <li className="font-medium text-white hover:underline cursor-pointer">
            <NavLink to="/login">{t("navbar_login")}</NavLink>
          </li>
          <li className="font-medium text-white hover:underline cursor-pointer">
            <NavLink to="/register">{t("navbar_register")}</NavLink>
          </li>
        </>
      )}
      {user && !isAnonymous && (
        <>
          <li className="font-medium text-white hover:underline cursor-pointer">
            <NavLink to="/credit-usage">
              {t("navbar_credit")} {`(${quota ?? 0})`}
            </NavLink>
          </li>
          <li className="font-medium text-white hover:underline cursor-pointer">
            <NavLink to="/documents">{t("navbar_document")}</NavLink>
          </li>
          <li className="font-medium text-white hover:underline cursor-pointer">
            <NavLink to="/settings">{t("navbar_setting")}</NavLink>
          </li>
          <li
            className="font-medium text-white hover:underline cursor-pointer"
            onClick={handleSingOut}
          >
            <p>{t("navbar_signout")}</p>
          </li>
        </>
      )}
      <select
        className="bg-transparent font-medium text-white cursor-pointer"
        value={i18n.language}
        onChange={handleChangeLanguage}
      >
        <option value="en">English</option>
        <option value="id">Indonesia</option>
        <option value="jp">Japanese</option>
        <option value="cn">Chinese</option>
        <option value="ar">Arabic</option>
      </select>
    </>
  );

  return (
    <nav className="fixed bg-primary w-full z-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="w-full flex items-center place-content-between">
            <HamburgerMenu quota={quota} />
            <div
              className="flex gap-2 items-center text-white cursor-pointer"
              onClick={handleNavigateToRoot}
            >
              <img
                className="md:h-10 md:w-10 w-7 h-7 md:block hidden"
                src="/asset/icon/Scan.svg"
                alt="logo"
              />
              <p className="md:text-2xl text-lg">STATELY</p>
              <img
                className="md:h-10 md:w-10 w-7 h-7 md:hidden block"
                src="/asset/icon/Scan.svg"
                alt="logo"
              />
              <span className="font-medium md:text-lg md:block text-base hidden">
                - {t("app")}
              </span>
            </div>
            <div className="navbar-center hidden lg:flex">
              <ul className="grid grid-flow-col px-1 gap-3">{navLinks}</ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
