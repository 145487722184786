import { useContext, useState } from "react";
import { AuthContext } from "../../provider/AuthProvider";
import Navbar from "../Navbar";
import { useTranslation } from "react-i18next";
import {
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/16/solid";

const ForgotPassword = () => {
  const { forgotPassword } = useContext(AuthContext);
  const { t } = useTranslation();

  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value;

    forgotPassword(email)
      .then((result) => {
        setMessage("Reset Password Email Sent");
      })
      .catch((error) => {
        setError(error);
      });
  };

  return (
    <>
      <Navbar />
      <section className="grid grid-cols-1 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 justify-items-center">
        <div className="w-full max-w-xl grid grid-cols-1 content-start items-start border border-gray-300 rounded-md p-[2rem] gap-y-6">
          <p className="text-[32px] font-bold text-start">
            {t("Reset Password")}
          </p>

          <form
            onSubmit={handleFormSubmit}
            className="grid grid-cols-1 gap-y-6"
          >
            {error && (
              <div className="p-2 border-2 border-red-500 rounded-lg">
                <InformationCircleIcon className="w-6 h-6 fill-red-500" />
                <p className="text-left text-red-500">{error}</p>
              </div>
            )}

            {message && (
              <div className="flex gap-x-2 p-2 border-2 border-green-500 rounded-lg">
                <CheckCircleIcon className="w-6 h-6 fill-green-500" />
                <p className="text-left text-green-500">{message}</p>
              </div>
            )}

            <input
              type="text"
              name="email"
              placeholder={t("login_email")}
              className="border border-gray-400 p-3 rounded-md font-semibold text-base"
            />

            <button className="bg-primary disabled:bg-opacity-20 rounded-md p-3 font-semibold text-white text-base">
              {t("login_button")}
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
