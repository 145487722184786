import { useContext } from "react";
import { AuthContext } from "../../provider/AuthProvider";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Settings = () => {
  const { user, isAnonymous } = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!user) {
    navigate("/");
  }

  const handleManageSubcription = async () => {
    if (isAnonymous) {
      navigate("/login");
    } else {
      try {
        window?.gtag("event", "click_manage_subscription"); // add event GA
        const formData = new FormData();

        await axios
          .post(
            `${process.env.REACT_APP_BASE_URL}payment/subscription`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${user.accessToken}`,
              },
            }
          )
          .then((response) => {
            // Handle successful file upload response
            const newWindow = window.open(
              response.data.redirect_url,
              "_blank",
              "noopener,noreferrer"
            );
            if (newWindow) newWindow.opener = null;
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <Navbar />
      <section className="grid grid-cols-1 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 justify-items-center">
        <div className="w-full max-w-xl grid grid-cols-1 content-start items-start border border-gray-300 rounded-md p-[2rem] gap-y-6">
          <p className="text-[32px] font-bold text-start">
            {t("setting_title")}
          </p>

          <button
            onClick={handleManageSubcription}
            className="border-2 bg-white disabled:bg-opacity-20 rounded-md p-3 font-semibold text-base grid grid-flow-col justify-center items-center gap-x-4 hover:text-primary hover:border-primary"
          >
            {t("setting_manage_subscription")}
          </button>
        </div>
      </section>
    </>
  );
};

export default Settings;
