import { useEffect, useState } from "react";
import AboutUs from "../AboutUs";
import Footer from "../Footer";
import Header from "../Header";
import Info from "../Info";
import Navbar from "../Navbar";
import Package from "../Package";
import PDFtoExcelConverter from "../PDFtoExcelConverter";

const Upload = () => {
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState("");

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("state") === "success") {
      setSuccess(true);
      setSessionId(query.get("session_id"));
      setMessage("Your payment was successful!");
    }

    if (query.get("state") === "failed") {
      setSuccess(false);
      setMessage("Your payment was failed, your balance is not withdrawn");
    }
  }, [sessionId]);

  return (
    <>
      <Navbar />
      <Header />

      {message && (
        <section className={"max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-4"}>
          <div
            className={
              success ? "bg-green-500 rounded-lg" : "bg-red-500 rounded-lg"
            }
          >
            <p className={"px-4 py-3 text-left text-white font-semibold"}>
              {message}
            </p>
          </div>
        </section>
      )}

      <PDFtoExcelConverter />
      <AboutUs />
      <Info />
      <Package />
      <Footer />
    </>
  );
};

export default Upload;
